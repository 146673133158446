// ** React Imports
import { useCallback } from 'react';
import { useEffect, createContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPetBaseColors,
  getPetBreeds,
  getPetFeLVStatus,
  getPetHWStatus,
  getPetTemperaments,
  getPetUniqueMedical
} from '../../redux/actions/addPet';

// ** Create Context
const AddPetContext = createContext({
  petBaseColorsOptions: [],
  petBreedsOptions: [],
  petUniqueMedicalOptions: [],
  petTemperamentsOptions: [],
  petHWOptions: [],
  petFeLVOptions: []
});

const AddPetProviderWrapper = ({ children }) => {
  const [petBaseColorsOptions, setPetBaseColorsOptions] = useState([]);
  const [petBreedsOptions, setPetBreedsOptions] = useState([]);
  const [petUniqueMedicalOptions, setPetUniqueMedicalOptions] = useState([]);
  const [petTemperamentsOptions, setPetTemperamentsOptions] = useState([]);
  const [petHWOptions, setPetHWOptions] = useState([]);
  const [petFeLVOptions, setPetFeLVOptions] = useState([]);

  const [petBreedsOfCat, setPetBreedsOfCat] = useState([]);
  const [petBreedsOfDog, setPetBreedsOfDog] = useState([]);

  const { isUserLoggedInState } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const selectOptionsGenerators = (apiPayload) => {
    const Options = apiPayload?.map((data) => {
      if (data.breeds) {
        const petBreeds = data.breeds.map((breed) => {
          return {
            id: breed.id,
            label: breed.name,
            value: breed.name
          };
        });
        return {
          id: data.id,
          name: data.name,
          breeds: petBreeds
        };
      }
      return {
        id: data.id,
        label: data.name.charAt(0).toUpperCase() + data.name.slice(1),
        name: data.name.charAt(0).toUpperCase() + data.name.slice(1),
        value: data.name.charAt(0).toUpperCase() + data.name.slice(1),
        pet_type_id: data.pet_type_id
      };
    });
    return Options;
  };

  useEffect(async () => {
    if (!isUserLoggedInState) {
      return;
    }

    // Pet Base Color Options
    try {
      const petBaseColorsRes = await dispatch(getPetBaseColors());
      if (petBaseColorsRes.result) {
        const petBaseColorsOptionsData = selectOptionsGenerators(
          petBaseColorsRes?.payload
        );
        setPetBaseColorsOptions(petBaseColorsOptionsData);
      }
    } catch (e) {
      console.log(e);
    }

    // Pet Breeds Options
    try {
      const petBreedRes = await dispatch(getPetBreeds());
      if (petBreedRes.result) {
        const petBreedsOptionsData = selectOptionsGenerators(
          petBreedRes?.payload
        );

        const petDogBreedsOptions = petBreedsOptionsData.find(
          (data) => data.name === 'dog'
        ).breeds;
        setPetBreedsOfDog(petDogBreedsOptions);
        const petCatBreedsOptions = petBreedsOptionsData.find(
          (data) => data.name === 'cat'
        ).breeds;
        setPetBreedsOfCat(petCatBreedsOptions);

        setPetBreedsOptions(petBreedsOptionsData);
      }
    } catch (e) {
      console.log(e);
    }

    // Pet Unique Medical Conditions
    try {
      const petUniqueMedicalRes = await dispatch(getPetUniqueMedical());
      if (petUniqueMedicalRes.result) {
        const petUniqueMedicalOptionsData = petUniqueMedicalRes?.payload;

        setPetUniqueMedicalOptions(petUniqueMedicalOptionsData);
      }
    } catch (e) {
      console.log(e);
    }

    // Pet Temperaments
    try {
      const petTemperamentsRes = await dispatch(getPetTemperaments());
      if (petTemperamentsRes.result) {
        const petTemperamentOptionsData = petTemperamentsRes?.payload;
        setPetTemperamentsOptions(petTemperamentOptionsData);
      }
    } catch (e) {
      console.log(e);
    }

    // Pet HeartWorm(HW+, HW-) Status Options
    try {
      const petHWRes = await dispatch(getPetHWStatus());
      if (petHWRes.result) {
        const petHWOptionsData = petHWRes?.payload;
        setPetHWOptions(petHWOptionsData);
      }
    } catch (e) {
      console.log(e);
    }

    // Pet FeLV Status Options
    try {
      const petFeLVRes = await dispatch(getPetFeLVStatus());
      if (petFeLVRes.result) {
        const petFeLVOptionsData = petFeLVRes?.payload;
        setPetFeLVOptions(petFeLVOptionsData);
      }
    } catch (e) {
      console.log(e);
    }
  }, [isUserLoggedInState]); /* Pet Form data */

  // console.log("petBaseColorsOptions", petBaseColorsOptions);
  // console.log("petBreedsOptions", petBreedsOptions);
  // console.log("petUniqueMedicalOptions", petUniqueMedicalOptions);
  // console.log("petTemperamentsOptions", petTemperamentsOptions);

  return (
    <AddPetContext.Provider
      value={{
        petHWOptions,
        petFeLVOptions,
        petBreedsOfCat,
        petBreedsOfDog,
        petBaseColorsOptions,
        petBreedsOptions,
        petUniqueMedicalOptions,
        petTemperamentsOptions
      }}
    >
      {children}
    </AddPetContext.Provider>
  );
};

export { AddPetProviderWrapper, AddPetContext };

