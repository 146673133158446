export const GET_USERS_LISTS = 'GET_USERS_LISTS';
export const GET_SELECTED_USER_CHAT = 'GET_SELECTED_USER_CHAT';
export const SELECT_USER = 'SELECT_USER';
export const SEND_RECEIVE_CHAT_MESSAGE = 'SEND_RECEIVE_CHAT_MESSAGE';
export const USER_CHAT_PAGINATION = 'USER_CHAT_PAGINATION';
export const USER_LISTS_API = 'USER_LISTS_API';
export const USER_CHATS_API = 'USER_CHATS_API';
export const CLEAR_SELECTED_CHATS = 'CLEAR_SELECTED_CHATS';
export const RECEIVE_NEW_USER_MESSAGE = 'RECEIVE_NEW_USER_MESSAGE';
export const UPDATE_UNREAD_COUNT = 'UPDATE_UNREAD_COUNT';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';

