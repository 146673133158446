import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PusherJS } from '../../pusher';
import {
  getChatUsersList,
  getSelectedUserChats,
  handleUserRead,
  handleUserSelection
} from '../../redux/actions/chat';
import {
  RECEIVE_NEW_USER_MESSAGE,
  SELECT_USER,
  SEND_RECEIVE_CHAT_MESSAGE,
  UPDATE_UNREAD_COUNT,
  DELETE_MESSAGE
} from '../../redux/actionTypes/chat';
import { store } from '../../redux/storeConfig/store';

const ChatContext = createContext({
  isUserListsLodeMoreError: false,
  isUserListsChatsPaginating: false,
  isUserListsChatsLastPage: false,
  setUserListsLodeMoreError: () => {},
  setUserListsChatsPaginating: () => {},
  setUserListsChatsLastPage: () => {},
  isChatsLodeMoreError: false,
  isChatsPaginating: false,
  isChatsLastPage: false,
  setChatsLodeMoreError: () => {},
  setChatsPaginating: () => {},
  setChatsLastPage: () => {}
});

const ChatProviderWrapper = ({ children }) => {
  const dispatch = useDispatch();

  const { isUserLoggedInState, userData } = useSelector((state) => state.auth);

  const {
    chatUsersListData,
    selectedUser,
    userListsApiState,
    selectedUserApiState
  } = useSelector((state) => state.chat);

  const usersList = chatUsersListData?.data ?? [];
  const [isUserListsLodeMoreError, setUserListsLodeMoreError] = useState(false);
  const [isUserListsChatsPaginating, setUserListsChatsPaginating] =
    useState(false);
  const [isUserListsChatsLastPage, setUserListsChatsLastPage] = useState(false);
  const selectedUserId = selectedUser?.id;
  const [isChatsLodeMoreError, setChatsLodeMoreError] = useState(false);
  const [isChatsPaginating, setChatsPaginating] = useState(false);
  const [isChatsLastPage, setChatsLastPage] = useState(false);
  const [connectedMembers, setConnectedMembers] = useState([]);

  useEffect(() => {
    if (!isUserLoggedInState) return;
    const fetchChatUserList = async () => {
      try {
        setUserListsChatsPaginating(true);
        setUserListsChatsLastPage(false);
        setUserListsLodeMoreError(false);

        const loadMoreUserListsRes = await dispatch(
          getChatUsersList(userListsApiState)
        );
        // console.log(loadMoreUserListsRes);
        if (loadMoreUserListsRes?.result) {
          if (
            loadMoreUserListsRes?.payload?.meta?.current_page >=
            loadMoreUserListsRes?.payload?.meta?.last_page
          ) {
            setUserListsChatsLastPage(true);
          }
        } else {
          setUserListsLodeMoreError(true);
        }
      } catch (e) {
        console.log(e);
        setUserListsLodeMoreError(true);

        // console.log(JSON.stringify(e));
      } finally {
        setUserListsChatsPaginating(false);
      }
    };
    fetchChatUserList();
  }, [userListsApiState, isUserLoggedInState]);

  useEffect(async () => {
    if (!isUserLoggedInState) return;
    if (
      Object.keys(selectedUser)?.length === 0
      // || selectedUserApiState?.message_id === ""
    ) {
      return;
    }
    const userChatsFetching = async (user) => {
      try {
        setChatsPaginating(true);
        setChatsLastPage(false);
        setChatsLodeMoreError(false);

        const loadChatsRes = await dispatch(
          getSelectedUserChats({
            groupId: selectedUser?.id,
            filteredData: selectedUserApiState
          })
        );

        if (loadChatsRes?.result) {
          if (loadChatsRes.payload?.data?.length) {
            setChatsLastPage(false);
          } else {
            setChatsLastPage(true);
          }
        } else {
          setChatsLodeMoreError(true);
        }
      } catch (e) {
        console.log(e);
        setChatsLodeMoreError(true);
      } finally {
        setChatsPaginating(false);
      }
    };
    userChatsFetching();
  }, [selectedUserApiState, isUserLoggedInState]);

  const onUserClick = (group) => {
    // const id = group?.id;

    try {
      const selectedUserChatRes = dispatch(handleUserSelection(group));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (Object.keys(userData)?.length === 0 || !isUserLoggedInState) {
      return;
    }

    const privatePusher = PusherJS();

    const privateChannelName = `private-App.User.${userData?.id}`;
    const privateChannel = privatePusher.subscribe(privateChannelName);

    privateChannel.bind('thread_for_user', (data) => {
      dispatch({
        type: RECEIVE_NEW_USER_MESSAGE,
        data: data
      });

      const currSelectedUser = store.getState().chat.selectedUser;

      // below code will update the group when it will be created or any value will change
      if (data?.id === currSelectedUser?.id) {
        if (
          data?.title !== currSelectedUser?.title ||
          data?.profile_image_url !== currSelectedUser?.profile_image_url
        ) {
          dispatch({
            type: SELECT_USER,
            data: data
          });
        }
        return;
      }
    });

    privateChannel.bind('count_for_user', (data) => {
      console.log('USER_UNREAD_COUNT:::::', data);
      dispatch({
        type: UPDATE_UNREAD_COUNT,
        data: data?.unread_message_thread_count
      });
    });
    // Turn below code on if anyone turn off reload on logout

    // return () => {
    //   privatePusher.unsubscribe(privateChannelName);
    // };
  }, [userData, isUserLoggedInState]);

  useEffect(() => {
    if (Object.keys(selectedUser)?.length === 0) {
      return;
    }

    const currChatChannelName = `presence-App.Thread.${selectedUserId}`;
    const pusher = PusherJS();

    const channel = pusher.subscribe(currChatChannelName);

    channel.bind('new_thread_message', (data) => {
      console.log('CHAT_MESSAGE:::::', data);

      const currSelectedUser = store.getState().chat.selectedUser;
      if (data?.sender_user?.id !== userData?.id) {
        if (data?.thread_id === currSelectedUser?.id) {
          dispatch({
            type: SEND_RECEIVE_CHAT_MESSAGE,
            data: data
          });
          return;
        }
      }
    });

    channel.bind('pusher:subscription_succeeded', (members) => {
      let membersIds = [];
      members.each((member) => {
        if (member.id !== members?.myID) {
          membersIds = [...membersIds, member.id];
        }
      });
      setConnectedMembers(membersIds);
      console.log(membersIds);
    });

    channel.bind('pusher:member_added', (member) => {
      setConnectedMembers((prevState) => {
        if (prevState.includes(member?.id)) {
          return prevState;
        }
        return [...prevState, member?.id];
      });
      console.log('member_added', member);
    });

    channel.bind('pusher:member_removed', (member) => {
      // For example
      setConnectedMembers((prevState) => {
        const updatedData = prevState;
        const memberIdIndex = prevState.indexOf(member?.id);
        if (memberIdIndex > -1) {
          prevState.splice(memberIdIndex, 1);
        }
        return updatedData;
      });
      console.log('member_removed', member);
    });

    channel.bind('message_deleted', (chat) => {
      dispatch({
        type: DELETE_MESSAGE,
        data: chat.id
      });
    });

    const readResetSelectedUserChat = async (user) => {
      try {
        const userReadRes = await dispatch(
          handleUserRead({
            groupId: selectedUserId
          })
        );

        dispatch({
          type: RECEIVE_NEW_USER_MESSAGE,
          data: userReadRes?.payload
        });
      } catch (e) {
        console.log(e);
      }
    };

    readResetSelectedUserChat();

    return () => {
      pusher.unsubscribe(currChatChannelName);

      setConnectedMembers([]);
    };
  }, [selectedUser]);

  // console.log("connectedMembers", connectedMembers);

  return (
    <ChatContext.Provider
      value={{
        isUserListsLodeMoreError,
        isUserListsChatsPaginating,
        isUserListsChatsLastPage,
        isChatsLodeMoreError,
        isChatsPaginating,
        isChatsLastPage,
        onUserClick,
        connectedMembers
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export { ChatProviderWrapper, ChatContext };

