import {
  SELECT_USER,
  GET_SELECTED_USER_CHAT,
  SEND_RECEIVE_CHAT_MESSAGE,
  GET_USERS_LISTS,
  USER_CHAT_PAGINATION,
  USER_LISTS_API,
  USER_CHATS_API,
  CLEAR_SELECTED_CHATS,
  RECEIVE_NEW_USER_MESSAGE,
  UPDATE_UNREAD_COUNT,
  DELETE_MESSAGE
} from '../../actionTypes/chat';

// **  Initial State
const initialState = {
  selectedUser: {},
  selectedUserChats: [],
  chatUsersListData: {},
  userListsApiState: { page: 1, per_page: 10, search: '' },
  selectedUserApiState: {
    message_id: '',
    per_page: 30
  },
  usersUnreadCount: 0
};

const chatReducer = (state = initialState, action) => {
  const chatUsersIds = state.chatUsersListData?.data?.map((user) => user?.id);

  switch (action.type) {
    case GET_USERS_LISTS:
      // console.log(action);
      let updateData = action.data.data;

      const prevData = state?.chatUsersListData?.data?.length
        ? state?.chatUsersListData?.data
        : [];

      if (!action?.isSearch) {
        if (chatUsersIds?.length) {
          updateData = action?.data?.data?.filter(
            (group) => !chatUsersIds?.includes(group?.id)
          );
        }
      }
      return {
        ...state,
        chatUsersListData: {
          ...state?.chatUsersListData,
          ...action.data,
          ...(!action?.isSearch && { data: [...prevData, ...updateData] }),
          ...(action?.isSearch && { data: [...updateData] })
        }
      };

    case SELECT_USER:
      return {
        ...state,
        selectedUser: action.data,
        selectedUserApiState: {
          ...state.selectedUserApiState,
          message_id: ''
        }
      };

    case GET_SELECTED_USER_CHAT:
      // console.log(action);
      return {
        ...state,
        selectedUserChats: action.data?.data?.reverse()
      };

    case USER_CHAT_PAGINATION:
      // console.log(action);
      return {
        ...state,
        selectedUserChats: [
          ...action.data?.data.reverse(),
          ...state.selectedUserChats
        ]
      };

    case SEND_RECEIVE_CHAT_MESSAGE:
      console.log(action);
      // const abcd = state.selectedUser?.id !== action?.sender_user?.id;
      console.log('SEND_RECEIVE_CHAT_MESSAGE');
      return {
        ...state,
        selectedUserChats: [...state.selectedUserChats, action.data]
      };

    case DELETE_MESSAGE:
      console.log(action);
      console.log('DELETE_MESSAGE');
      const updatedChats = state.selectedUserChats.filter(
        (chat) => chat.id !== action.data
      );
      return {
        ...state,
        selectedUserChats: updatedChats
      };

    case RECEIVE_NEW_USER_MESSAGE:
      let updatedData = [...state.chatUsersListData?.data];

      if (chatUsersIds?.includes(action.data?.id)) {
        updatedData = updatedData?.map((user) => {
          if (user?.id === action.data.id) {
            return action.data;
          } else {
            return user;
          }
        });
      } else if (!state.userListsApiState.search) {
        //prevent adding broadcast to list if there is search ongoing and user is not in search result list
        updatedData = [...state.chatUsersListData?.data, action?.data];
      }

      return {
        ...state,
        chatUsersListData: {
          ...state.chatUsersListData,
          data: [...updatedData]
        }
        // selectedUser:{}
      };

    case USER_LISTS_API:
      console.log(action);
      return {
        ...state,
        userListsApiState: {
          ...action?.data
        }
      };

    case USER_CHATS_API:
      console.log(action);
      return {
        ...state,
        selectedUserApiState: {
          ...state.selectedUserApiState,
          message_id: action?.data?.message_id
        }
      };

    case CLEAR_SELECTED_CHATS:
      return {
        ...state,
        selectedUserApiState: {
          ...state.selectedUserApiState,
          message_id: ''
        },
        userListsApiState: state.userListsApiState.search
          ? { ...state.userListsApiState, page: 1, search: '', per_page: 10 }
          : state.userListsApiState, //if user searched in userlist it will clear and will get latest data
        selectedUser: {},
        selectedUserChats: []
      };

    case UPDATE_UNREAD_COUNT:
      return {
        ...state,
        usersUnreadCount: action?.data
      };
    default:
      return state;
  }
};

export default chatReducer;

